import React, { useState, useContext } from "react"
import styled from "styled-components"
import { Link, NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import media from "./Media"
import Logo from "../logo.webp"
import SubmitButton from "./SubmitButton"
import checkObjectValue from "../utils/checkObjectValue"
import UpdatePasswordApi from "../apis/UpdatePasswordApi"
import AuthContext from "../contexts/Authentication"
import { actions } from "../contexts/actions"

const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
  background-color: #f7f5f0;
  border-bottom: 1px solid #d5cfc1;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  ${media.mobile`
    > * {
      flex: 1 100%;
    }
  `};
`

const LogoIcon = styled(Link)`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 30px;
  margin: 0.5rem;
  margin-right: 20px;

  ${media.mobile`
    flex: 1 0 100%;
  `};
`

const MenuBar = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 12px 10px;

  ${media.desktop`
    flex: 4 0px;
    order: 2;
  `};
  ${media.mobile`
    flex: 2 0px;

  `};
`

const MenuItem = styled(NavLink)`
  text-decoration: none !important;
  font-size: 18px;
  margin-right: 20px;
  cursor: pointer;
  color: #d5cfc1;

  &.active {
    color: #574f39;
  }

  :hover {
    color: #574f39;
  }

  ${media.mobile`
    font-size: 14px;
  `};
`

const AccountOptions = styled.div`
  display: flex;
  flex-flow: row nowrap;

  ${media.desktop`
    order: 3;
    margin-right: 10px;
  `};
  ${media.mobile`
    justify-content: flex-end;
  `};
`

const AccountItem = styled.div`
  text-decoration: none !important;
  font-size: 15px;
  color: #d5cfc1;
  cursor: pointer;

  :hover {
    color: #574f39;
  }
  ${media.desktop`
    margin-top: 25px;
    margin-left: 15px;
  `};
  ${media.mobile`
    margin-right: 20px;
    font-size: 14px;
  `};
`

const UpdatePasswordWindow = styled.div`
  position: absolute;
  z-index: 100;
  top: 3.5rem;
  right: 20px;
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
`

const UpdatePasswordWindowArrowBorder = styled.div`
  position: absolute;
  top: -15px;
  right: 15px;
  width: 0px;
  height: 0px;
  border: 15px solid transparent;
  border-bottom: 15px solid #d5cfc1;

  ${media.mobile`
    top: 55px;
  `};
`

const UpdatePasswordWindowArrow = styled.div`
  position: absolute;
  z-index: 1;
  top: -14px;
  right: 15px;
  width: 0px;
  height: 0px;
  border: 15px solid transparent;
  border-bottom: 15px solid #f6f6f8;

  ${media.mobile`
    top: 55px;
  `};
`

const UpdatePasswordWindowBody = styled.div`
  position: absolute;
  right: 0px;
  top: 15px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #d5cfc1;
  background-color: #f6f6f8;
  padding: 1rem;

  ${media.mobile`
    top: 85px;
  `};
`

const Input = styled.input`
  display: block;
  width: 10rem;
  padding: 0.375rem 0.75rem;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  border: 1px solid #efefef;
  &:focus {
    outline: none;
    border: solid 2px #ffc13c;
  }
`

const Message = styled.div`
  padding-left: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #d43f3a;
`

const Buttons = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-top: 5px;
`

const CloseButton = styled.button`
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  border: none;
  cursor: ${({ disable }) => (disable ? "" : "pointer")};
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 14px;

  ${media.mobile`
    margin-top: 10px;
  `};
`

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 5;
  width: 100%;
  height: 100%;
  display: ${props => (props.isOpen ? "block" : "none")};
`

function Header() {
  const { t } = useTranslation("header")
  const { user, dispatchUser } = useContext(AuthContext)

  const logout = async () => {
    dispatchUser({ type: actions.CLEAR_USER })
    window.amplitude.getInstance().logEvent("logged out", {})
    await window.amplitude.getInstance().setUserId(null)
  }

  const [isOpen, setIsOpen] = useState(false)
  const [pwd, setPwd] = useState({
    oldPassword: "",
    newPassword: "",
    checkNewPassword: "",
  })
  const [message, setMessage] = useState({
    content: "",
    color: "#d43f3a",
  })

  const toggleOpen = () => {
    setIsOpen(isOpen => !isOpen)
  }

  const closePop = () => {
    setIsOpen(false)
    setPwd({
      oldPassword: "",
      newPassword: "",
      checkNewPassword: "",
    })
    setMessage({
      content: "",
      color: "#d43f3a",
    })
  }

  const setPwdValue = (key, value) => {
    const newPwd = { ...pwd }
    newPwd[key] = value
    setPwd(newPwd)
  }

  const submit = () => {
    if (!checkObjectValue(pwd)) {
      setMessage({
        content: t("updatePassword.message.password_is_empty"),
        color: "#d43f3a",
      })
    } else if (pwd["oldPassword"] === pwd["newPassword"]) {
      setMessage({
        content: t("updatePassword.message.password_didnt_change"),
        color: "#d43f3a",
      })
    } else if (pwd["newPassword"] !== pwd["checkNewPassword"]) {
      setMessage({
        content: t("updatePassword.message.confirm_password_failed"),
        color: "#d43f3a",
      })
    } else {
      const id = user.profile.id
      UpdatePasswordApi(id, pwd).then(response => {
        if (response.status === 200) {
          setMessage({
            content: t("updatePassword.message.success"),
            color: "#357ebd",
          })
          window.amplitude.getInstance().logEvent("updated password", {})
        } else {
          setMessage({
            content: t("updatePassword.message.failed"),
            color: "#d43f3a",
          })
        }
      })
    }
  }

  return (
    <React.Fragment>
      <Mask
        isOpen={isOpen}
        onMouseDown={() => {
          closePop()
        }}
      />
      <HeaderWrapper>
        <LogoIcon to="/revenue" />
        {user ? (
          <React.Fragment>
            <MenuBar>
              <MenuItem to="/revenue" activeClassName="active">
                {t("menu.monthlyReport")}
              </MenuItem>
              {!user && (
                <React.Fragment>
                  <MenuItem to="/article_reads" activeClassName="active">
                    {t("menu.articleReads")}
                  </MenuItem>
                  <MenuItem to="/article_analytics" activeClassName="active">
                    {t("menu.articleAnalytics")}
                  </MenuItem>
                </React.Fragment>
              )}
            </MenuBar>
            <AccountOptions>
              <AccountItem
                onClick={e => {
                  e.preventDefault()
                  logout()
                }}
              >
                {t("logout")}
              </AccountItem>
              <AccountItem
                onMouseDown={() => {
                  toggleOpen()
                }}
              >
                {t("updatePassword.title")}
              </AccountItem>
            </AccountOptions>
          </React.Fragment>
        ) : null}
        <UpdatePasswordWindow isOpen={isOpen}>
          <UpdatePasswordWindowArrowBorder />
          <UpdatePasswordWindowArrow />
          <UpdatePasswordWindowBody>
            <Input
              type="password"
              placeholder={t("updatePassword.text.oldPassword")}
              value={pwd["oldPassword"]}
              onChange={e => setPwdValue("oldPassword", e.target.value)}
            />
            <Input
              type="password"
              placeholder={t("updatePassword.text.newPassword")}
              value={pwd["newPassword"]}
              onChange={e => setPwdValue("newPassword", e.target.value)}
            />
            <Input
              type="password"
              placeholder={t("updatePassword.text.confirmPassword")}
              value={pwd["checkNewPassword"]}
              onChange={e => setPwdValue("checkNewPassword", e.target.value)}
            />
            <Message style={{ color: message["color"] }}>
              {message["content"]}
            </Message>
            <Buttons>
              <SubmitButton
                onClick={() => {
                  submit()
                }}
              >
                {t("updatePassword.button.submit")}
              </SubmitButton>
              <CloseButton
                onMouseDown={() => {
                  closePop()
                }}
              >
                {t("updatePassword.button.cancel")}
              </CloseButton>
            </Buttons>
          </UpdatePasswordWindowBody>
        </UpdatePasswordWindow>
      </HeaderWrapper>
    </React.Fragment>
  )
}

export default Header
